import { useForm, validationError } from '@rvf/react-router'
import { withZod } from '@rvf/zod'
import {
	type LoaderFunctionArgs,
	type ActionFunctionArgs,
	Link,
	redirect,
	useActionData,
} from 'react-router'
import { HoneypotInputs } from 'remix-utils/honeypot/react'
import { z } from 'zod'
import { LogoIcon } from '#app/assets'
import { RVFInput } from '#app/components/index.ts'
import { Button } from '#app/components/ui/button'
import {
	Card,
	CardHeader,
	CardTitle,
	CardDescription,
	CardContent,
} from '#app/components/ui/card'
import { ROUTE_PATHS } from '#app/const/routes.ts'
import { getAlphaInvitation } from '#app/services/alphaInvitations.server.ts'
import { createAuthSession, getUser } from '#app/services/auth.server.ts'
import { checkHoneypot } from '#app/utils/honeypot.server.ts'
import { type loggerType } from '../../../server/utils/logger'

export const validator = withZod(
	z.object({
		email: z.string().email('Invalid email address'),
	}),
)

export async function loader({ request }: LoaderFunctionArgs) {
	const user = await getUser(request)
	if (user) {
		return redirect(ROUTE_PATHS.DASHBOARD.HOME.BASE)
	}

	return {}
}

export async function action({ request, context }: ActionFunctionArgs) {
	const { logger } = context as { logger: loggerType }
	const formData = await request.formData()
	await checkHoneypot(formData)

	const url = new URL(request.url)
	const param = url.searchParams.get('redirectTo')
	let redirectTo = param ? encodeURIComponent(param) : ''

	const result = await validator.validate(formData)

	if (result.error) return validationError(result.error)

	const { error } = await getAlphaInvitation(result.submittedData.email, logger)

	logger.info(
		`Login attempt for email: ${result.submittedData.email}`,
		`error: ${error}`,
	)
	if (error) return error

	return createAuthSession({
		request,
		email: result.submittedData.email,
		redirectTo: `${ROUTE_PATHS.AUTH.VERIFY}?redirectTo=${redirectTo}`,
	})
}

export default function Login() {
	const actionData = useActionData<typeof action>()

	const form = useForm({
		validator,
		method: 'post',
	})
	return (
		<div className="flex h-screen items-center justify-center bg-gray-100 dark:bg-gray-950">
			<div className="w-full max-w-md">
				<div className="mb-6 flex items-center justify-between">
					<Link to="/" className="flex items-center" prefetch="intent">
						<LogoIcon className="size-6" />
						<span className="sr-only">Empirium Inc</span>
					</Link>
				</div>
				<Card>
					<CardHeader className="space-y-1">
						<CardTitle className="text-2xl font-bold">Login</CardTitle>
						<CardDescription>
							Enter your email to create or access your account.
						</CardDescription>
					</CardHeader>
					<CardContent>
						<form className="space-y-4" {...form.getFormProps()}>
							<HoneypotInputs />
							<div className="space-y-2">
								<RVFInput
									form={form}
									label="email"
									placeholder="m@example.com"
									name="email"
									type="email"
								/>
							</div>
							{(actionData as any)?.error && (
								<p className="text-sm text-red-500" role="alert">
									{(actionData as any).error}
								</p>
							)}
							<Button
								type="submit"
								className="w-full"
								disabled={form.formState.isSubmitting}
							>
								{form.formState.isSubmitting ? 'Loading...' : 'Sign in'}
							</Button>
						</form>
					</CardContent>
				</Card>
			</div>
		</div>
	)
}
